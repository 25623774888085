@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.own-candidate-modal {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 800px;

  .own-candidate-modal__image {
    width: 400px;
    min-height: 590px;
    background-image: url('../../../../public/images/own_candidate.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .own-candidate-modal__form {
    width: 320px;
    background-color: $color-grey;
    display: flex;
    flex-direction: column;
    padding: 40px;

    .site-header3 {
      padding-bottom: 15px;
    }

    .own-candidate-modal__form__state-container {
      display: flex;

      label {
        margin-bottom: 15px;

        input {
          height: auto;
        }

        span {
          margin: auto 10px auto 0;
          @include font-size(16px);
        }
      }
    }

    .own-candidate-modal__form__instructions {
      @include font-size(16px);
      padding: 0 0 15px 0;
    }

    .own-candidate-modal__form__input {
      margin: 0 0 15px 0;
    }

    .own-candidate-modal__form__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      .own-candidate-modal__form__buttons__submit {
        margin: 0;
      }

      .own-candidate-modal__form__buttons__close {
        margin: 0;
        color: $color-white;
        background-color: transparent;
        border: 2px solid $color-white;
      }
    }

    .own-candidate-modal__form__switch-link-container {
      &:last-of-type {
        margin-bottom: 20px;
      }

      a {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .own-candidate-modal {
    width: 100%;
    height: 100vh;
    grid-template-columns: 100%;

    .own-candidate-modal__form {
      width: calc(100% - 80px);
      .own-candidate-modal__form__buttons {
        margin: 30px 0 auto 0;
      }
    }

    .own-candidate-modal__image {
      display: none;
    }
  }
}
