@import 'src/styles/_variables.scss';

.icon-select-container {
  position: relative;

  .icon-select-container__icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .icon-select-container__input {
    padding: 4px 0 0 45px;
    width: 100%;
    box-sizing: border-box;

    &:required:invalid {
      color: $color-light-grey;
      font-style: italic;
    }

    option[value=''][disabled] {
      display: none;
    }

    option {
      color: $color-black;
      font-style: normal;
    }
  }

  .icon-select-container__input--invalid {
    border: 2px solid $color-orange;
    background-position: right 23px top 16px, right 13px top 16px;
    background-image: linear-gradient(45deg, transparent 50%, $color-orange 50%),
      linear-gradient(135deg, $color-orange 50%, transparent 50%);

    &:required:invalid {
      color: $color-orange;
    }
  }
}
