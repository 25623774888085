@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.voting-summary {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px 0px;
  overflow: hidden;

  .voting-summary__list-container {
    &:first-of-type {
      grid-row: 1 / 4;
    }

    .voting-summary__list-container__title {
      margin-bottom: 20px;
    }

    .voting-summary__list-container__list {
      margin: 0 auto;

      .voting-summary__list-container__list__item {
        margin: 0 0 10px 0;
        height: 50px;
        display: flex;
        color: $color-white;

        .voting-summary__list-container__list-item__order-text {
          height: 50px;
          width: 60px;
          display: flex;
          background: $color-grey;
          text-align: center;
          align-items: center;

          &-number {
            margin: 0 auto 0 auto;
            color: $color-gold;
            @include font-size(26px);
            font-family: 'Oswald', sans-serif;
            font-weight: 500;
            @include user-select(none);
            line-height: 1;
          }

          & > div {
            flex: auto;
          }

          &-position {
            color: $color-white;
            @include font-size(12px);
            white-space: nowrap;
            margin: 0 0 0 0;
          }
        }

        .voting-summary__list-container__list-item__container {
          display: flex;
          height: 50px;
          flex: 1;
          margin: 0;
          padding: 0;

          .voting-summary__list-container__list-item__container__image {
            box-sizing: border-box;
            padding: 0;
            height: 50px;
            width: 63px;
            display: inline-block;
            @include user-select(none);
            object-fit: cover;
            object-position: 100% 0;
          }

          .voting-summary__list-container__list-item__container__text-container {
            background-color: transparent;
            padding: 0px 10px;
            height: 50px;
            flex: 1;
            @include font-size(24px);
            @include user-select(none);
            font-family: 'Oswald', sans-serif;
            margin: auto 0;
            font-weight: bold;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 380px;

            .voting-summary__list-container__list-item__container__text-container__name {
              color: $color-white;
            }

            .voting-summary__list-container__list-item__container__text-container__info {
              p {
                color: $color-gold;
                @include font-size(14px);
                text-transform: uppercase;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

.voting-summary-info {
  text-align: center;
  margin: 60px 0;
}

@media screen and (max-width: 1350px) {
  .voting-summary {
    grid-template-columns: 100%;
    grid-gap: 5px;

    .voting-summary__list-container {
      padding: 0 20px;

      &:first-of-type {
        grid-row: 1 / 1;
      }

      .voting-summary__list-container__list {
        margin: 0 auto 26px auto;

        .voting-summary__list-container__list__item {
          height: 40px;

          .voting-summary__list-container__list-item__order-text {
            height: 40px;
            width: 40px;
            min-width: 40px;

            &-number {
              @include font-size(18px);
            }

            &-position {
              @include font-size(10px);
            }
          }

          .voting-summary__list-container__list-item__container {
            height: 40px;
            max-width: calc(100% - 40px);

            .voting-summary__list-container__list-item__container__image {
              height: 40px;
              width: 45px;
            }

            .voting-summary__list-container__list-item__container__text-container {
              padding: 0 10px;
              height: 40px;
              @include font-size(18px);

              .voting-summary__list-container__list-item__container__text-container__info {
                @include font-size(10px);
              }
            }
          }
        }
      }
    }
  }
}
