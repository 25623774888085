@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.results-archive {
  padding: 23vw 20px 0 20px;
  width: 1160px;
  margin: 0 auto;

  .results-archive__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    max-height: calc(100% - 40px);
    min-height: 320px;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
  }

  .results-archive__container {
    .results-archive__container__latest {
      .results-archive__container__latest__title {
        margin-bottom: 75px;

        .site-header2 {
          @include font-size(76px);
          text-transform: uppercase;
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
        }
      }
    }

    .results-archive__container__older-results-title {
      margin: 80px 0 50px 0;

      .site-header2 {
        @include font-size(42px);
        text-transform: uppercase;
        font-weight: 500;
        font-family: 'Oswald', sans-serif;
      }
    }

    .results-archive__container__older-results {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      margin: -20px;

      .results-archive__container__older-results-archive__item {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .results-archive__container__older-results-archive__item__year {
          @include font-size(42px);
          text-transform: uppercase;
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
          color: $color-gold;
          text-align: center;
        }

        .results-archive__container__older-results-archive__item__name {
          @include font-size(30px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
          color: $color-white;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .results-archive {
    width: calc(100% - 40px);
    padding: 22vw 20px 0 20px;

    .results-archive__container {
      .results-archive__container__latest {
        .results-archive__container__latest__title {
          margin: 0 auto 40px auto;

          .site-header2 {
            @include font-size(46px);
            margin: 0 auto;
          }
        }
      }

      .results-archive__container__older-results-title {
        margin: 40px auto;
        display: block;

        .site-header2 {
          @include font-size(32px);
          margin: 0 auto;
        }
      }

      .results-archive__container__older-results {
        grid-template-columns: 320px;
        width: 320px;
        margin: 0 auto;

        .results-archive__container__older-results-archive__item {
          .results-archive__container__older-results-archive__item__year {
            @include font-size(32px);
          }

          .results-archive__container__older-results-archive__item__name {
            @include font-size(24px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .results-archive {
    width: calc(100% - 20px);
    padding: 22vw 10px 0 10px;

    .results-archive__container {
      .results-archive__container__older-results {
        grid-template-columns: calc(100% - 20px);
        width: calc(100% - 20px);
      }
    }
  }
}
