@import 'src/styles/_variables.scss';

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-black;
  color: $color-white;
  line-height: 1.333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-weight: bold;
  color: $color-gold;

  &:visited {
    color: $color-gold;
  }
}
