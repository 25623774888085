@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.frontpage {
  padding: 0;
  width: 100%;
  margin: -80px 0 0 0;

  .frontpage__carousel {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
    height: 900px;

    .frontpage__carousel__image-bottom-fade {
      display: none;
    }

    .carousel__master-spinner-container {
      background-color: $color-grey;
    }

    .frontpage__carousel__dotgroup {
      position: absolute;
      @include transform(translate(0, -50px));
      left: 0;
      text-align: center;
      width: 100%;

      button {
        padding: 0;
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin: 0 12px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 4px solid $color-light-grey;
        cursor: pointer;
        background-color: transparent;
        opacity: 1;
        font-size: 0;

        span {
          display: none;
        }

        &.carousel__dot--selected {
          border: 4px solid $color-white;
          background-color: $color-white;
        }
      }
    }

    .carousel__slide-focus-ring {
      display: none;
    }

    .frontpage__carousel__item {
      height: 900px !important;
      padding-bottom: 0 !important;
      width: 100%;

      table {
        width: 100%;
        border-spacing: 0px;
        border-collapse: separate;

        td {
          padding: 0px;
        }

        hr.__se__dashed {
          border: 0;
          border-top: 1px dashed $color-white;
        }

        hr.__se__solid {
          border: 0;
          border-top: 1px solid $color-white;
        }

        hr.__se__dotted {
          border: 0;
          border-top: 1px dotted $color-white;
        }
      }

      .frontpage__carousel__item__image {
        width: 100%;
        height: 900px;
        @include user-select(none);
        object-fit: cover;
        object-position: 50% 0;
      }

      .frontpage__carousel__item__container {
        width: 480px;
        height: 495px;
        position: absolute;
        top: 256px;
        left: 50%;
        padding: 25px 40px 40px 40px;
        z-index: 1;
        display: grid;
        grid-gap: 20px;
        grid-template-rows: auto 1fr auto;
        text-align: left;

        .frontpage__carousel__item__container__layout {
          background-color: $color-black;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        .frontpage__carousel__item__container__title {
          z-index: 3;
          @include font-size(42px);
          text-transform: uppercase;
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
        }

        .frontpage__carousel__item__container__text {
          z-index: 3;
          @include font-size(18px);
          @include line-height(28px);
          font-weight: 500;
          max-height: 280px;
          overflow: hidden;

          a {
            color: $color-white;
            text-decoration: none;
            font-weight: normal;
            pointer-events: none;
          }

          b {
            font-weight: 900 !important;
          }
        }

        .frontpage__carousel__item__container__read-more-button {
          margin: 0 auto;
          display: block;
          min-height: 40px;
          z-index: 3;
        }
      }
    }
  }

  .frontpage__category-container {
    background-color: $color-grey;

    .frontpage__category-container__title,
    .frontpage__category-container__title-result {
      padding: 50px 0 60px 0;
    }

    .frontpage__category-container__candidates-revealed-text {
      margin: 10px auto 30px auto;
      text-align: center;
    }

    .frontpage__category-container__title-result {
      & + .frontpage__category-container__items-container {
        justify-content: center;
      }
    }

    .frontpage__category-container__items-container {
      width: auto;
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto 60px auto;

      &:after {
        content: '';
        height: 1;
        display: block;
        clear: both;
      }

      .frontpage__category-container__items-container__results-revealed-item {
        .frontpage__category-container__items-container__results-revealed-item__image {
          width: 100%;
          @include user-select(none);
          margin: 0 auto 15px auto;
          object-fit: cover;
          object-position: 50% 0;
        }
      }

      &-results {
        width: 100%;
        max-width: 1400px;
      }

      &__result {
        text-decoration: none;
        width: calc(25% - 30px);
        padding: 0 15px;
        display: block;
        float: left;

        &--image {
          display: block;
          width: 100%;
          height: auto;
          padding: 0 0 100% 0;
          background-position: center center;
          background-size: auto 100%;
          border-radius: 50%;
          position: relative;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
          overflow: hidden;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: linear-gradient(transparent, rgb(51, 51, 51));
            height: 50%;
            width: 100%;
            z-index: 1;
          }

          img {
            position: absolute;
            left: 25%;
            bottom: 30px;
            width: 50%;
            z-index: 2;
          }
        }

        &--text {
          margin-top: 20px;
          margin-bottom: 40px;
          font-size: 24px;
          font-weight: 500;
          font-family: Oswald, sans-serif;
          color: $color-white;
          text-transform: uppercase;
          text-align: center;
          display: block;
          text-decoration: none;
        }

        &--sport {
          font-size: 14px;
          font-weight: 500;
          font-family: Oswald, sans-serif;
          color: #ddb54c;
          text-transform: uppercase;
          display: block;
          text-align: center;
          display: block;
          text-decoration: none;
        }
      }

      .frontpage__category-container__items-container__item {
        width: 360px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        .frontpage__category-container__items-container__item__logo {
          margin: 0 auto;
          height: 130px;
          width: 323px;
        }

        .frontpage__category-container__items-container__item__logo-small {
          margin: 0 auto;
          height: 80px;
          width: 200px;
        }
      }
    }

    .frontpage__category-container__items-container--narrow {
      width: 800px;
      margin: 0 auto 20px auto;
    }

    .frontpage__category-container__made-by-codemate-image {
      margin: 0 auto;
      max-height: 60px;
      display: block;
      padding: 40px 0;
      max-width: calc(100% - 80px);
    }

    .frontpage__category-container__made-by-codemate-image--smaller {
      max-height: 48px !important;
    }
  }

  .frontpage__introduction-container {
    position: relative;

    .frontpage__introduction-container__image {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      @include user-select(none);
      object-fit: cover;
      object-position: 100% 0;
      max-height: calc(100% + 300px);
    }

    .frontpage__introduction-container__title {
      padding: 70px 0 42px 0;
    }

    .frontpage__introduction-container__text {
      width: 1160px;
      margin: 0 auto;
      @include font-size(22px);
      @include line-height(30px);
      font-weight: 500;
    }

    .frontpage__introduction-container__brand {
      margin: 40px auto 60px;
      max-width: 90%;
      text-align: center;

      .frontpage__introduction-container__brand__link__image {
        max-width: 100%;
      }
    }

    .frontpage__introduction-container__collaboration-title {
      padding: 50px 0 60px 0;
    }

    .frontpage__introduction-container__collaboration-container {
      width: 1160px;
      margin: 0 auto;
      display: flex;
      gap: 30px;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;

      .frontpage__introduction-container__collaboration_block {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        max-width: 100%;
        width: 980px;

        .frontpage__introduction-container__collaboration-container__link {
          max-width: 30%;

          .frontpage__introduction-container__collaboration-container__link__image {
            max-width: 100%;
            width: 300px;
          }
        }
      }

      .frontpage__introduction-container__collaboration_banner {
        text-align: -webkit-center;
        max-width: 100%;

        .frontpage__introduction-container__collaboration-container__link__image {
          max-width: 100%;
          width: 980px;
        }
      }

      .frontpage__introduction-container__collaboration-container__link {
        margin: 0;

        .frontpage__introduction-container__collaboration-container__link__image {
          margin: 0;
          display: block;
        }
      }
    }
  }

  .frontpage__newsarchive-container {
    padding: 0 20px 0 20px;
    width: 1160px;
    margin: 0 auto;

    .frontpage__newsarchive__title {
      margin: 50px 0 20px 0;
    }

    .frontpage__newsarchive__newsitem-container {
      .frontpage__newsarchive__date {
        color: $color-gold;
        text-transform: uppercase;
        font-weight: 500;
        @include font-size(24px);
        font-family: 'Oswald', sans-serif;
        margin-bottom: 20px;
      }
    }

    .frontpage__newsarchive__newsitem-container:hover,
    .frontpage__newsarchive__newsitem-container:active {
      cursor: pointer;
    }

    button {
      position: absolute;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      min-height: 40px;
      min-width: 300px;
      z-index: 2;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .frontpage {
    width: 100%;

    .frontpage__carousel {
      width: 100%;
      height: 600px;

      .frontpage__carousel__empty-image {
        width: 100%;
        height: 300px;
        @include user-select(none);
        object-fit: cover;
        object-position: 50% 0;
      }

      .frontpage__carousel__dotgroup {
        button {
          min-width: 20px;
          width: 20px;
          height: 20px;
          border: 3px solid $color-light-grey;

          &.carousel__dot--selected {
            border: 3px solid $color-white;
          }
        }
      }

      .frontpage__carousel__item {
        height: 600px !important;
        padding-bottom: 0 !important;

        .frontpage__carousel__item__image {
          width: 100%;
          height: 300px;
          @include user-select(none);
          object-fit: cover;
          object-position: 50% 0;
        }

        .frontpage__carousel__item__image-bottom-fade {
          display: block;
          position: absolute;
          top: 200px;
          height: 100px;
          width: 100%;
          z-index: 1;
          background: linear-gradient(
            0deg,
            $color-black 0%,
            rgba(20, 20, 20, 0) 100%
          );
        }

        .frontpage__carousel__item__container {
          width: calc(100% - 40px);
          height: 300px;
          top: 200px;
          right: 0;
          left: unset;
          padding: 25px 20px 40px 20px;

          .frontpage__carousel__item__container__layout {
            background-color: transparent;
          }

          .frontpage__carousel__item__container__title {
            @include font-size(24px);
            z-index: 2;
          }

          .frontpage__carousel__item__container__text {
            @include font-size(16px);
            @include line-height(25px);
            max-height: 175px;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              height: 50%;
              z-index: 10;
              box-shadow: 0 -45px 45px $color-black inset;
              left: -50%;
              width: 200%;
            }
          }

          .frontpage__carousel__item__container__read-more-button {
            margin-top: -35px;
          }
        }
      }
    }

    .frontpage__category-container {
      .frontpage__category-container__title {
        padding: 30px 0;

        .site-header2 {
          @include font-size(24px);
        }
      }

      .frontpage__category-container__items-container {
        width: calc(100% - 40px);
        margin: 0 auto;
        display: block;

        .frontpage__category-container__items-container__item {
          width: 100%;

          .frontpage__category-container__items-container__item__logo {
            height: 87px;
            margin-bottom: 20px;
            max-width: 100%;
            width: 216px;
          }

          .frontpage__category-container__items-container__item__logo-small {
            height: 45px;
            width: 112px;
          }
        }
      }

      .frontpage__category-container__items-container--narrow {
        flex-direction: row;
      }

      .frontpage__category-container__made-by-codemate-image {
        padding: 20px 0;
      }
    }

    .frontpage__introduction-container {
      .frontpage__introduction-container__image {
        height: auto;
      }

      .frontpage__introduction-container__text {
        width: calc(100% - 40px);
        @include font-size(16px);
        @include line-height(22px);
      }

      .frontpage__introduction-container__title {
        padding: 30px 0 20px 0;

        .site-header2 {
          @include font-size(24px);
        }
      }
      .frontpage__introduction-container__collaboration-title {
        padding: 30px 0 20px 0;

        .site-header2 {
          @include font-size(24px);
        }
      }

      .frontpage__introduction-container__collaboration-container {
        width: calc(100% - 40px);
        flex-direction: column;

        .frontpage__introduction-container__collaboration_banner {
          max-width: 80%;
          .frontpage__introduction-container__collaboration-container__link {
            margin: 0;
          }
        }
      }
    }
  }
  .frontpage__newsarchive-container {
    width: auto !important;
  }
}

@media screen and (max-width: 700px) {
  .frontpage {
    .frontpage__category-container {
      .frontpage__category-container__title-result {
        .border-title__title {
          font-size: 32px;
        }
      }

      .frontpage__category-container__items-container {
        &__result {
          width: calc(50% - 30px);

          &--text {
            font-size: 18px;
          }

          &:nth-of-type(2n + 1) {
            clear: both;
          }
        }
      }
    }

    .frontpage__introduction-container {
      .frontpage__introduction-container__collaboration-container {
        .frontpage__introduction-container__collaboration_block {
          width: 320px;
          .frontpage__introduction-container__collaboration-container__link {
            max-width: 100%;
          }
        }
        .frontpage__introduction-container__collaboration_banner {
          max-width: 100%;
        }
      }
    }
  }
}
