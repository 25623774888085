@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.route-container {
  padding: 80px 0;
  position: relative;
}

em {
  font-style: italic;
}

strong {
  font-weight: 900;
}

.site-header1 {
  @include font-size(90px);
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  letter-spacing: -1px;
}

.site-header2 {
  @include font-size(70px);
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  letter-spacing: -1px;
}

.site-header3 {
  @include font-size(40px);
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  letter-spacing: -1px;
}

// Styles for text created in SunEditor (used in Admin-FrontEnd for creating news items)
.text-from-newsEditor {
  h1 {
    font-size: 2em;
    line-height: 1.333;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.333;
  }

  h3 {
    font-size: 1.17em;
    line-height: 1.333;
  }

  h4 {
    font-size: 1em;
    line-height: 1.333;
  }

  h5 {
    font-size: 0.83em;
    line-height: 1.333;
  }
  h6 {
    font-size: 0.67em;
    line-height: 1.333;
  }

  blockquote {
    margin: 4px 0 0 0;
    line-height: 1.5;
    border-style: solid;
    border-color: #b1b1b1;
    padding: 0 0 0 7px;
    border-left-width: 5px;
  }

  ol {
    list-style-position: outside;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  hr.__se__solid {
    border-style: solid none none;
  }
  hr.__se__dotted {
    border-style: dotted none none;
  }
  hr.__se__dashed {
    border-style: dashed none none;
  }
}

button {
  font-family: 'Oswald', sans-serif;
  border: 0;
  background-color: $color-white;
  color: $color-black;
  cursor: pointer;
  @include font-size(18px);
  color: $color-black;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 20px;
  height: 40px;
  min-width: 120px;
  @include user-select(none);
  -webkit-tap-highlight-color: transparent;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }
}

select {
  @include font-size(16px);
  border: 0;
  cursor: pointer;
  height: 40px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, $color-gold 50%),
    linear-gradient(135deg, $color-gold 50%, transparent 50%);
  background-position: right 25px top 18px, right 15px top 18px;
  background-size: 10px 10px, 10px 10px;
}

input {
  @include font-size(16px);
  border: 0;
  height: 40px;

  &::placeholder {
    color: $color-light-grey;
    font-style: italic;
  }
}

textarea {
  font-family: 'Lato', sans-serif;
  @include font-size(16px);
  border: 0;

  &::placeholder {
    color: $color-light-grey;
    font-style: italic;
  }
}

@media screen and (max-width: 1350px) {
  .route-container {
    margin-top: 60px;
  }

  .site-header1 {
    @include font-size(50px);
  }

  .site-header2 {
    @include font-size(42px);
  }

  .site-header3 {
    @include font-size(32px);
  }
}

@media screen and (max-width: 900px) {
  .site-header1 {
    @include font-size(50px);
  }

  .site-header2 {
    @include font-size(42px);
  }
}

@media screen and (max-width: 600px) {
  .site-header1 {
    @include font-size(40px);
  }

  .site-header2 {
    @include font-size(32px);
  }
}
