@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.voting {
  width: 1160px;
  padding: 15vw 20px 0 20px;
  margin: 0 auto;

  .voting__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    min-height: 320px;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
  }

  .voting__title-step-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    .voting__instructions-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .voting__instructions-container__title {
        @include font-size(100px);
        text-transform: uppercase;
        font-weight: 600;
        width: 400px;
        line-height: 100px;
        margin: auto 0;
      }

      .voting__instructions-container__image {
        height: 210px;
        margin: 0 auto 0 0;
        width: 523px;
      }

      .voting__instructions-container__instructions {
        @include font-size(24px);
        @include line-height(32px);
        font-weight: 500;
        margin-bottom: 12px;

        b {
          font-weight: 900 !important;
        }
      }

      .voting__instructions-container__link-container {
        .voting__instructions-container__link-container__icon {
          margin-right: 20px;
        }

        .voting__instructions-container__link-container__link {
          @include font-size(24px);
          @include line-height(32px);
          vertical-align: top;
        }
      }
    }

    .voting__voting-step-container {
      width: 100%;
      display: flex;

      .voting__voting-step-container__step {
        flex: 1;
        background-color: $color-black;
        font-weight: 500;
        @include font-size(21px);
        font-family: 'Oswald', sans-serif;
        text-align: center;
        height: 70px;
        padding: 10px 0 15px 0;
        line-height: 32px;
        text-transform: uppercase;
        cursor: pointer;
        border-bottom: 3px solid $color-black;
        margin: 0 0 40px 2px;
        @include user-select(none);

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          color: $color-gold;
        }

        .voting__voting-step-container__step__index {
          @include font-size(40px);
          line-height: 42px;
        }
      }

      .voting__voting-step-container__step--selected {
        background-color: $color-gold;
        border-bottom: 3px solid $color-gold;

        &:hover {
          color: $color-white;
        }
      }

      .voting__voting-step-container__step--disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  .voting__voting-navbar {
    background-color: $color-white;
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;

    .voting__voting-navbar__container {
      display: flex;
      padding: 20px;
      justify-content: center;
      width: 1160px;
      margin: 0 auto;

      .voting__voting-navbar__container__info-container {
        display: flex;
        flex: 1;
        justify-content: center;

        .voting__voting-navbar__container__info-container__selection-text,
        .voting__voting-navbar__container__info-container__category-text {
          color: $color-black;
          @include font-size(24px);
          font-weight: 500;
          text-transform: uppercase;
          font-family: 'Oswald', sans-serif;
        }

        .voting__voting-navbar__container__info-container__selection-text {
          margin: auto 50px auto 10px;
        }

        .voting__voting-navbar__container__info-container__category-text {
          margin: auto 10px auto 50px;
        }
      }

      .voting__voting-navbar__container__nav-button {
        display: flex;
        width: 260px;

        .voting__voting-navbar__container__nav-button__icon {
          margin: 2px 10px 0 10px;
        }

        .voting__voting-navbar__container__nav-button__text {
          flex: 1;
          margin: auto 0;
          text-align: center;
        }
      }

      .voting__voting-navbar__container__nav-button--prev {
        border: 2px solid $color-black;
        justify-content: flex-start;
        padding-right: 25px;
        color: $color-black;
      }

      .voting__voting-navbar__container__nav-button--next {
        background-color: $color-gold;
        color: $color-white;
        justify-content: flex-end;
        padding-left: 25px;
      }

      .voting__voting-navbar__container__nav-button--send {
        background-color: $color-green;
        color: $color-white;
        justify-content: flex-end;
        padding-left: 25px;
      }

      .voting__voting-navbar__container__nav-button--hidden {
        visibility: hidden !important;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .voting {
    width: 100%;
    padding: 0;
    margin: 0;

    .voting__title-step-container {
      flex-direction: column-reverse;

      .voting__instructions-container {
        height: auto;
        margin: 0 20px 20px 20px;

        .voting__instructions-container__title {
          @include font-size(38px);
          margin: auto 20px;
          line-height: 38px;
          margin-bottom: 20px;
          width: 200px;
        }

        .voting__instructions-container__image {
          height: 80px;
          width: 200px;
          margin: 0 auto 0 0;
        }

        .voting__instructions-container__instructions {
          @include font-size(16px);
          @include line-height(20px);
          font-weight: 500;
          margin-bottom: 12px;

          b {
            font-weight: 900 !important;
          }
        }

        .voting__instructions-container__link-container {
          .voting__instructions-container__link-container__icon {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
          }

          .voting__instructions-container__link-container__link {
            @include font-size(16px);
            @include line-height(18px);
            vertical-align: top;
          }
        }
      }

      .voting__voting-step-container {
        .voting__voting-step-container__step {
          height: 40px;
          margin: 0 0 40px 2px;

          .voting__voting-step-container__step__index {
            @include font-size(24px);
            line-height: 30px;
          }
          .voting__voting-step-container__step__name {
            @include font-size(8px);
            line-height: 16px;
          }
        }
      }
    }

    .voting__voting-navbar {
      height: 60px;

      .voting__voting-navbar__container {
        display: flex;
        padding: 10px;
        width: calc(100% - 20px);

        .voting__voting-navbar__container__info-container {
          flex-direction: column;
          text-align: center;

          .voting__voting-navbar__container__info-container__category-text {
            @include font-size(12px);
            margin: auto;
          }

          .voting__voting-navbar__container__info-container__selection-text {
            @include font-size(18px);
            margin: auto;
          }
        }

        .voting__voting-navbar__container__nav-button {
          width: 40px;
          min-width: 40px;
          position: relative;

          .voting__voting-navbar__container__nav-button__icon {
            padding: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }

          .voting__voting-navbar__container__nav-button__text {
            display: none;
          }
        }

        .voting__voting-navbar__container__nav-button--prev {
          padding-right: 0;
        }

        .voting__voting-navbar__container__nav-button--next {
          padding-left: 0;
        }

        .voting__voting-navbar__container__nav-button--send {
          padding-left: 0;
        }
      }
    }
  }
}
