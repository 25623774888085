@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.login-modal {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 800px;

  .login-modal__image {
    width: 400px;
    height: 520px;
    background-image: url('../../../../public/images/winner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .login-modal__form {
    width: 320px;
    background-color: $color-grey;
    display: flex;
    flex-direction: column;
    padding: 40px;

    .site-header3 {
      padding-bottom: 20px;
    }

    .login-modal__form__instructions {
      @include font-size(16px);
      padding: 0 0 20px 0;
    }

    .login-modal__form__help-instructions {
      @include font-size(16px);
      margin: 10px 0;
    }

    .login-modal__form__input {
      margin: 0 0 20px 0;
    }

    .login-modal__form__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      .login-modal__form__buttons__submit {
        margin: 0;
      }

      .login-modal__form__buttons__close {
        margin: 0;
        color: $color-white;
        background-color: transparent;
        border: 2px solid $color-white;
      }
    }

    .login-modal__form__switch-link-container {
      &:last-of-type {
        margin-bottom: 20px;
      }

      a {
        margin-left: 5px;
      }
    }

    .login-modal__form_recaptcha-container {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 900px) {
  .login-modal {
    width: 100%;
    height: 100vh;
    grid-template-columns: 100%;

    .login-modal__form {
      width: calc(100% - 80px);

      .login-modal__form__buttons {
        margin: 30px 0 auto 0;
      }
    }

    .login-modal__image {
      display: none;
    }
  }
}
