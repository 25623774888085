@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.voting-category {
  .voting-category__category-text {
    text-transform: uppercase;
  }

  .voting-category__list-container {
    .voting-category__list-container__selectable-title {
      margin-bottom: 20px;
    }

    .voting-category__list-container__list {
      margin: 0 auto 60px auto;

      .voting-category__list-container__list__item {
        margin: 0 0 10px 0;
        height: auto;
        display: flex;
        color: $color-white;

        .voting-category__list-container__list-item__order-text {
          width: 80px;
          display: flex;
          background: $color-grey;
          text-align: center;
          align-items: center;

          & > div {
            flex: auto;
          }

          &-number {
            margin: 0 auto 0 auto;
            color: $color-gold;
            @include font-size(42px);
            font-family: 'Oswald', sans-serif;
            font-weight: 500;
            @include user-select(none);
          }

          &-position {
            color: $color-white;
            @include font-size(18px);
            white-space: nowrap;
            margin: 0 0 10px 0;
          }
        }

        .voting-category__list-container__list-item__container {
          display: flex;
          height: auto;
          flex: 1;
          margin: 0;
          padding: 0;

          .voting-category__list-container__list-item__container__image {
            box-sizing: border-box;
            padding: 0;
            height: auto;
            width: 100px;
            display: inline-block;
            @include user-select(none);
            object-fit: cover;
            object-position: 50% 0;
          }

          .voting-category__list-container__list-item__container__text-container--selected {
            background-color: $color-gold !important;

            .voting-category__list-container__list-item__container__text-container__info {
              color: $color-black !important;
            }
          }

          .voting-category__list-container__list-item__container__text-container {
            background-color: $color-grey;
            padding: 10px 20px;
            height: calc(100% - 20px);
            flex: 1;
            @include font-size(24px);
            @include user-select(none);
            font-family: 'Oswald', sans-serif;
            margin: auto 0;
            font-weight: bold;

            .voting-category__list-container__list-item__container__text-container__name {
              color: $color-white;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .voting-category__list-container__list-item__container__text-container__info {
              color: $color-gold;
              display: grid;
              grid-template-columns: auto auto 1fr;
              @include font-size(18px);

              p {
                margin-right: 5px;
                text-transform: uppercase;
                overflow: hidden;
              }

              .voting-category__list-container__list-item__container__text-container__info__separator {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $color-white;
                margin: 10px 5px auto 0;
              }
            }
          }

          .voting-category__list-container__list-item__container__controls-container--selected {
            background-color: $color-gold !important;
          }

          .voting-category__list-container__list-item__container__controls-container {
            background-color: $color-grey;
            height: auto;
            display: flex;

            .voting-category__list-container__list-item__container__controls-container__choose-button {
              margin: 20px;
              min-width: 80px;
              font-weight: 500;
            }

            .voting-category__list-container__list-item__container__controls-container__icon-button--drag {
              cursor: grab;
            }

            .voting-category__list-container__list-item__container__controls-container__icon-button--click {
              cursor: pointer;
            }

            .voting-category__list-container__list-item__container__controls-container__icon-button {
              height: 100%;
              width: 60px;
              position: relative;
              margin-left: 2px;
              border-left: 2px solid $color-black;

              &:first-of-type {
                margin-left: 0;
                border-left: 0;
              }

              .voting-category__list-container__list-item__container__controls-container__icon-button__drag-layout {
                z-index: 3;
                background-color: transparent;
                width: 60px;
                height: 80px;
                position: absolute;
                cursor: grab;
              }

              .voting-category__list-container__list-item__container__controls-container__icon-button__icon {
                opacity: 0.6;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .voting-category {
    padding: 0 20px;

    .voting-category__list-container {
      .voting-category__list-container__list {
        .voting-category__list-container__list__item {
          height: auto;

          .voting-category__list-container__list-item__order-text {
            height: auto;
            width: 40px;
            min-width: 40px;

            &-number {
              @include font-size(24px);
            }

            &-position {
              @include font-size(10px);
              margin: 0 0 5px;
            }
          }

          .voting-category__list-container__list-item__container {
            height: auto;

            .voting-category__list-container__list-item__container__image {
              height: auto;
              width: 46px;
            }
            .voting-category__list-container__list-item__container__text-container--selected {
              width: calc(100% - 120px) !important;
            }

            .voting-category__list-container__list-item__container__text-container {
              padding: 0 10px;
              height: 100%;
              width: calc(100% - 190px);

              .voting-category__list-container__list-item__container__text-container__name {
                margin-top: 3px;
                @include font-size(14px);
              }

              .voting-category__list-container__list-item__container__text-container__info {
                @include font-size(10px);

                p {
                  margin-bottom: 5px;
                }

                .voting-category__list-container__list-item__container__text-container__info__separator {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: $color-white;
                  margin: 5px 5px auto 0;
                }
              }
            }

            .voting-category__list-container__list-item__container__controls-container {
              height: auto;

              .voting-category__list-container__list-item__container__controls-container__icon-button--click {
                .voting-category__list-container__list-item__container__controls-container__icon-button__icon {
                  width: 20px !important;
                  height: 20px !important;
                }
              }

              .voting-category__list-container__list-item__container__controls-container__icon-button--drag {
                .voting-category__list-container__list-item__container__controls-container__icon-button__icon {
                  width: 20px !important;
                  height: 20px !important;
                }
              }

              .voting-category__list-container__list-item__container__controls-container__choose-button {
                height: 25px;
                @include font-size(12px);
                min-width: 60px;
                margin: 8px 8px 7px 0;
              }

              .voting-category__list-container__list-item__container__controls-container__icon-button {
                height: 100%;
                width: 25px;

                .voting-category__list-container__list-item__container__controls-container__icon-button__drag-layout {
                  width: 25px;
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
