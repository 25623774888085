.resetPasswordPage {
  padding: 6vw 20px 0 20px;
  width: 400px;
  margin: 0 auto;

  button {
    margin-top: 15px;
  }

  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
