@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.CookieConsentBanner {
  background-color: $color-black;
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  display: flex;
  z-index: 99;
  flex-flow: nowrap;

  .CookieConsentBanner__consentTextContainer {
    height: 100%;
    width: 61%;

    .CookieConsentBanner__consentTextContainer-consentText {
      position: absolute;
      top: 50%;
      right: 40%;
      padding-right: 20px;
      padding-left: 20px;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 18px;
    }
  }

  .CookieConsentBanner__showMoreContainer {
    min-width: 110px;
    height: 100%;

    .CookieConsentBanner__showMoreContainer-showMoreLink {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .CookieConsentBanner__AcceptButtonContainer {
    width: 30%;
    height: 100%;

    .CookieConsentBanner__AcceptButtonContainer-AcceptButton {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      min-height: 40px;
      min-width: 190px;
    }
  }
}

@media screen and (max-width: 730px) {
  .CookieConsentBanner {
    height: 170px;
    flex-flow: wrap;

    .CookieConsentBanner__consentTextContainer {
      min-width: 100%;
      height: 50%;

      .CookieConsentBanner__consentTextContainer-consentText {
        right: 0;
        top: 0;
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }
    .CookieConsentBanner__showMoreContainer {
      min-width: 50%;
      text-align: center;

      .CookieConsentBanner__showMoreContainer-showMoreLink {
        top: 70%;
      }
    }
    .CookieConsentBanner__AcceptButtonContainer {
      min-width: 50%;
      .CookieConsentBanner__AcceptButtonContainer-AcceptButton {
        top: 70%;
        min-height: 20px;
        min-width: 155px;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .CookieConsentBanner {
    .CookieConsentBanner__consentTextContainer {
      .CookieConsentBanner__consentTextContainer-consentText {
        font-size: 4vmin;
      }
    }
  }
}
