@import 'src/styles/_mixins.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow: auto;

  .modal-backdrop {
    background-color: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }

  .modal-body {
    position: absolute;
    left: 50%;
    top: 30%;
    @include transform(translate(-50%, -30%));
    max-width: 100%;
    padding: 0;
    margin: 0;
    overflow-y: auto;

    .modal-body__close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 900px) {
  .modal {
    .modal-body {
      left: 0;
      top: 0;
      @include transform(translate(0, 0));
      width: 100%;
      height: 100%;
    }
  }
}
