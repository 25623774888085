@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

// Note that some of the styles used in news__container__text are located in App.scss (because they are used both in NewsPage and Frontpage)

.news {
  padding: 5vw 20px 0 20px;
  width: 960px;
  margin: 0 auto;
  min-height: 600px;

  .news__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
    height: 1000px;
  }

  .news__bg-image-fade {
    position: absolute;
    top: 900px;
    left: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(
      0deg,
      $color-black 0%,
      rgba(51, 51, 51, 0) 100%
    );
    z-index: -1;
  }

  .news__bg-layout {
    background-color: $color-black;
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .news__container {
    .news__container__title {
      @include font-size(42px);
    }

    .news__container__created {
      font-style: italic;
      @include font-size(16px);
    }

    .news__container__text {
      margin-top: 60px;
      @include font-size(22px);
      @include line-height(30px);

      b {
        font-weight: 900 !important;
      }

      p {
        margin-bottom: 15px;
      }

      table {
        width: 100%;
        border-spacing: 0px;
        border-collapse: separate;

        td {
          padding: 0px;
        }

        hr.__se__dashed {
          border: 0;
          border-top: 1px dashed $color-white;
        }

        hr.__se__solid {
          border: 0;
          border-top: 1px solid $color-white;
        }

        hr.__se__dotted {
          border: 0;
          border-top: 1px dotted $color-white;
        }
      }

      ul {
        display: block;
        list-style-type: disc;

        li {
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .news {
    width: calc(100% - 40px);
    min-height: 200px;

    .news__bg-image {
      height: 400px;
    }
    .news__bg-image-fade {
      top: 350px;
      height: 50px;
    }

    .news__container {
      .news__container__title {
        @include font-size(32px);
      }

      .news__container__text {
        @include font-size(16px);
        @include line-height(22px);

        .news__container__text__title {
          @include font-size(20px);
          @include line-height(30px);
        }
      }
    }
  }
}
