$color-black: #141414;
$color-white: #fff;
$color-grey: #333;
$color-light-grey: #777;
$color-gold: #ddb54c;
$color-orange: #e95617;
$color-light-orange: #fcede6;

$color-red: #c00;
$color-green: #0c0;
