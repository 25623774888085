@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.bg-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.has-voted-page {
  padding: 15vw 20px 0 20px;
  width: 900px;
  margin: 0 auto;
  text-align: center;

  .site-header3 {
    word-wrap: break-word;

    &:first-of-type {
      margin-bottom: 40px;
    }
  }

  a {
    @include font-size(40px);
    margin: 70px auto 0 auto;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
  }
}

@media screen and (max-width: 1350px) {
  .has-voted-page {
    a {
      @include font-size(32px);
    }
  }
}

@media screen and (max-width: 900px) {
  .has-voted-page {
    width: calc(100% - 40px);

    .site-header3 {
      @include font-size(22px);

      &:first-of-type {
        margin-bottom: 40px;
      }
    }

    a {
      margin: 50px auto 0 auto;
      @include font-size(22px);
    }
  }
}
