@import 'src/styles/_variables.scss';

.icon-datepicker-container {
  position: relative;

  .icon-datepicker-container__icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .icon-input-container__alert-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .icon-datepicker-container__datepicker {
      padding: 4px 0 0 45px;
      width: 100%;
      box-sizing: border-box;
    }

    .icon-datepicker-container__datepicker--invalid {
      border: 2px solid $color-orange;
      padding: 4px 45px 0 45px;

      &::placeholder {
        color: $color-orange;
      }
    }

    .react-datepicker__close-icon {
      padding: 0 15px;
      margin: 0;
      min-width: 0;
      top: -2px;

      &::after {
        font-size: 40px;
        background-color: transparent;
        color: $color-white;
      }
    }
  }

  .react-datepicker-popper {
    .react-datepicker__year-read-view {
      display: flex;
      flex-direction: row-reverse;
      height: 30px;

      span {
        float: none;
        margin: auto 3px;
        top: 0;
      }
    }

    button {
      min-width: 0;
    }
  }
}
