@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.results {
  padding: 22vw 20px 0 20px;
  width: 1160px;
  margin: 0 auto;

  .results__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    max-height: calc(100% - 40px);
    min-height: 320px;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
  }

  .results__container {
    .results__container__title {
      margin-bottom: 75px;

      .site-header2 {
        @include font-size(76px);
        text-transform: uppercase;
        font-weight: 500;
        font-family: 'Oswald', sans-serif;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .results {
    width: calc(100% - 40px);
    padding: 22vw 20px 0 20px;

    .results__container {
      .results__container__title {
        margin: 0 auto 40px auto;

        .site-header2 {
          @include font-size(46px);
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .results {
    width: calc(100% - 20px);
    padding: 22vw 10px 0 10px;

    .results__container {
      .results__container__title {
        width: 280px;
      }
    }
  }
}
