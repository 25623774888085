@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.navbar__bg {
  background-color: $color-black;
  opacity: 0.6;
  width: calc(100%);
  max-width: calc(100vw - 1em);
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.navbar {
  position: absolute;
  height: 80px;
  top: 0;
  left: 0;
  width: calc(100%);
  max-width: calc(100vw - 1em);
  margin: 0 auto;
  z-index: 100;
  white-space: nowrap;

  -webkit-tap-highlight-color: transparent;

  .navbar__layout {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 0;
    width: calc(100%);
    max-width: calc(100vw - 1em);
    height: 100vh;
  }

  .navbar__logo {
    display: none;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 50%;
    @include transform(translate(-50%, 0));
  }

  .navbar__logo:hover {
    cursor: pointer;
  }

  .navbar__list {
    margin: 0 auto;
    padding: 0;
    width: 1160px;
    display: flex;
    flex: 1;
    justify-content: space-between;

    .navbar__list__item-container {
      display: flex;
      margin: 0;
      padding: 0;

      .navbar__list__item-container__logo {
        height: 50px;
        margin: auto 30px auto 0;
      }

      .navbar__list__item-container__logo:hover {
        cursor: pointer;
      }
      .navbar__list__item-container__item {
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        @include font-size(18px);
        font-weight: bold;
        height: 100%;
        box-sizing: border-box;
        cursor: pointer;

        &__overlay {
          display: none;
        }

        &--open {
          .navbar__list__item-container__item {
            &__overlay {
              display: block;
              position: fixed;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background: transparent;
              padding: 0;
              margin: 0;
              cursor: default;
              z-index: -1;
            }
          }
        }

        a,
        span {
          color: $color-white;
          text-decoration: none;
          padding: 30px 22px;
          height: 20px;
          display: inline-block;
          @include user-select(none);
          margin: auto 0;
          vertical-align: middle;
          outline: none;

          &:hover {
            color: $color-gold !important;
          }
        }

        .navbar__list__item-container__item__sublinks {
          display: none;
          position: absolute;
          background-color: $color-black;
          min-width: 200px;
          flex-direction: column;
          text-align: left;
          z-index: 2;

          .navbar__list__item-container__item__sublinks__link--active {
            color: $color-gold !important;
          }
        }

        .navbar__list__item-container__item__sublink-icon {
          display: none;
        }
      }

      .navbar__list__item-container__item--active {
        a,
        span {
          color: $color-gold !important;
        }
      }
    }

    .navbar__list__login-container {
      .navbar__list__login-container__login-button,
      .navbar__list__login-container__logout-button {
        color: $color-white;
        padding: 0 22px;
        height: 80px;
        display: inline-block;
        cursor: pointer;
        font-family: 'Oswald', sans-serif;
        @include font-size(18px);
        text-transform: uppercase;
        font-weight: bold;
        @include user-select(none);
        background-color: transparent;
        border: 0;
        box-sizing: border-box;
        text-decoration: none;

        &:hover {
          color: $color-gold !important;
        }

        p {
          height: 20px;
          padding: 30px 0;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .navbar__toggle-button {
    color: $color-white;
    cursor: pointer;
    display: none;
    margin: 0 0 auto auto;
    height: 50px;
    width: 50px;
    padding: 15px;
    @include user-select(none);
    outline: none;

    img {
      width: 35px;
      margin: auto;
      display: block;
    }
  }
}

@media only screen and (min-width: 1349px) {
  .navbar {
    .navbar__list {
      .navbar__list__item-container {
        .navbar__list__item-container__item--open {
          background-color: $color-black;
          span {
            background-color: $color-black;
          }

          .navbar__list__item-container__item__sublinks {
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .navbar__bg {
    height: 60px;
  }

  .navbar {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: unset;
    z-index: 10000;
    height: 60px;

    .navbar__logo {
      display: block;
    }

    .navbar__list {
      display: none;

      .navbar__list__item-container {
        flex-direction: column;

        .navbar__list__item-container__logo {
          display: none;
        }

        .navbar__list__item-container__item {
          min-height: 60px;
          margin-bottom: 20px;

          a,
          span {
            padding: 20px;
          }

          &:hover {
            background-color: $color-grey;
          }

          .navbar__list__item-container__item__sublinks--active {
            display: flex !important;
          }

          .navbar__list__item-container__item__sublinks {
            position: relative;
            display: none;
            background-color: $color-grey;
            width: 100%;
            text-align: center;

            .navbar__list__item-container__item__sublinks__link {
              font-weight: normal;
              @include font-size(16px);
              padding: 15px 20px;
            }
          }

          .navbar__list__item-container__item__sublink-icon {
            display: inline-block;
            position: absolute;
            margin-left: 5px;
          }
        }
      }

      .navbar__list__login-container {
        .navbar__list__login-container__login-button,
        .navbar__list__login-container__logout-button {
          display: block;
          height: 60px;

          p {
            height: 20px;
            padding: 20px 0;
          }
        }
      }
    }

    .navbar__list--toggled {
      display: block;
      position: absolute;
      padding-top: 50px;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-height: calc(100% - 60px);
      overflow-y: auto;

      .navbar__list__item-container__item {
        display: block;

        a {
          display: block;
        }
      }
    }

    .navbar__toggle-button {
      z-index: 10001;
      display: inline-flex;
      padding: 5px 10px;
    }
  }

  .navbar--toggled {
    background-color: $color-grey !important;
    position: fixed;
    min-height: 100vh;
  }
}
