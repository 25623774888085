@import 'src/styles/_mixins.scss';
@import 'src/styles/_variables.scss';

.snackbar {
  position: fixed;
  top: -300px;
  @include transition(top 0.6s ease-out);
  left: 50%;
  @include transform(translate(-50%, 0));
  color: $color-white;
  z-index: 20000;
  padding: 10px 10px;
  min-width: 300px;
  max-width: 700px;
  text-align: center;
  border-radius: 5px;

  .snackbar__container {
    display: flex;
    justify-content: space-between;

    .snackbar__container__text {
      @include user-select(none);
      flex: 1;
      margin: auto 10px;
      @include font-size(18px);
    }

    .snackbar__container__close-button {
      cursor: pointer;
      padding: 5px 0;
      margin: auto 5px auto auto;
      height: 29px;
    }
  }
}

.snackbar--error {
  background-color: $color-red;
}

.snackbar--success {
  background-color: $color-green;
}

.snackbar--visible {
  top: 30px;
}

@media screen and (max-width: 700px) {
  .snackbar {
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);

    .snackbar__container {
      .snackbar__container__close-button {
        margin: auto 15px auto 5px;
      }
    }
  }

  .snackbar--visible {
    top: 0;
  }
}
