@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.instructions-modal {
  display: flex;
  flex-direction: column;
  width: 720px;
  padding: 40px;
  background-color: $color-grey;

  .instructions-modal__content-container {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 20px;

    .instructions-modal__content-container__title {
      color: $color-gold;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
      @include font-size(24px);
      font-family: 'Oswald', sans-serif;
    }

    .instructions-modal__content-container__text {
      @include font-size(18px);
      margin-bottom: 20px;

      b {
        font-weight: 900 !important;
      }
    }
  }

  .instructions-modal__close-button {
    margin: 30px auto 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .instructions-modal {
    width: calc(100% - 80px);

    .instructions-modal__content-container {
      max-height: 100%;
      height: 100%;
    }
  }
}
