@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin user-select($args...) {
  -webkit-user-select: $args;
  -moz-user-select: $args;
  -ms-user-select: $args;
  -o-user-select: $args;
  user-select: $args;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
}

@mixin line-height($size) {
  line-height: $size;
}
