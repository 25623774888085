@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.common-form {
  .common-form__bg_image_upper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  .common-form__form-container {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 800px;
    margin: 100px auto 0 auto;

    .common-form__form-container__image {
      width: 400px;
      height: 650px;
      background-image: url('../../../public/images/winner.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
    }

    .common-form__form-container__form {
      width: 320px;
      background-color: $color-grey;
      display: flex;
      flex-direction: column;
      padding: 40px;

      .common-form__form-container__form__title {
        margin: 0;
        padding: 0 0 20px 0;
      }

      .common-form__form-container__form__instructions {
        @include font-size(16px);
        padding: 0 0 20px 0;
      }

      .common-form__form-container__form__input {
        margin: 0 0 20px 0;
      }

      .common-form__form-container__form__action-button {
        margin: auto 0 0 auto;
      }

      .common-form__form-container__form__multi-button-container {
        display: flex;
        justify-content: space-between;
        margin-top: auto;

        .common-form__form-container__form__action-button {
          margin: 0;
        }

        .common-form__form-container__form__back-button {
          margin: 0;
          color: $color-white;
          background-color: transparent;
          border: 2px solid $color-white;
        }
      }

      .common-form__form-container__form__finished-container {
        text-align: center;
        margin: auto 50px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .common-form {
    .common-form__form-container {
      width: 400px;
      grid-template-columns: 100%;

      .common-form__form-container__image {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .common-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .common-form__bg_image_upper {
      display: none;
    }

    .common-form__form-container {
      width: 100%;
      margin: 0;
      height: 100vh;

      .common-form__form-container__form {
        width: calc(100% - 80px);
        padding-top: 100px;

        .common-form__form-container__form__action-button {
          margin: 0 0 auto auto;
        }

        .common-form__form-container__form__multi-button-container {
          display: flex;
          justify-content: space-between;
          margin: 0 0 auto 0;

          .common-form__form-container__form__action-button {
            margin: 0;
          }

          .common-form__form-container__form__back-button {
            margin: 0;
            color: $color-white;
            background-color: transparent;
            border: 2px solid $color-white;
          }
        }
      }
    }
  }
}
