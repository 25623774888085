@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.candidate {
  padding: 22vw 20px 0 20px;
  width: 1160px;
  margin: 0 auto;

  .candidate__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    min-height: 320px;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
  }

  .candidate__container {
    &__credits {
      margin: 90px 0 0;
      text-align: center;
    }

    .candidate__container__title {
      margin: 0 auto 90px auto;

      .site-header2 {
        @include font-size(76px);
      }
    }

    .candidate__container__introduction {
      @include font-size(22px);
      @include line-height(30px);
      font-weight: 500;
      margin: 0 auto 40px auto;
    }

    .candidate__container__candidates-text {
      margin-bottom: 30px;
    }

    .candidate__container__grid {
      display: grid;
      grid-template-columns: 360px 360px 360px;
      grid-gap: 40px;

      .candidate__container__grid__item {
        display: flex;
        flex-direction: column;
        position: relative;

        .candidate__container__grid__item__image {
          width: 360px;
          height: 330px;
          margin-bottom: 150px;
          @include user-select(none);
          object-fit: cover;
          object-position: 50% 0;
        }

        .candidate__container__grid__item__info-layout {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 300px;
          background: linear-gradient(
            0deg,
            $color-grey 0%,
            $color-grey 50%,
            rgba(51, 51, 51, 0) 100%
          );
        }

        .candidate__container__grid__item__info-container {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;

          .candidate__container__grid__item__info-container__list-container {
            display: flex;
            flex-direction: column;
            background-color: transparent;
            padding: 20px;
            width: calc(100% - 40px);

            .candidate__container__grid__item__info-container__list-container__sport {
              text-transform: uppercase;
              color: $color-gold;
              @include font-size(18px);
              font-family: 'Oswald', sans-serif;
              font-weight: 500;
              line-height: 26px;
              padding-bottom: 10px;
            }
            .candidate__container__grid__item__info-container__list-container__name {
              text-transform: uppercase;
              color: $color-white;
              @include font-size(24px);
              font-family: 'Oswald', sans-serif;
              font-weight: 500;
              line-height: 32px;
            }

            .candidate__container__grid__item__info-container__list-container__achievements {
              min-height: 54px;

              .candidate__container__grid__item__info-container__list-container__achievements__item {
                display: flex;
                margin-bottom: 4px;

                .candidate__container__grid__item__info-container__list-container__achievements__item__icon {
                  margin: 3px 5px auto 0;
                  min-width: 1.2rem;
                }

                .candidate__container__grid__item__info-container__list-container__achievements__item__text {
                  font-family: 'Oswald', sans-serif;
                  @include font-size(18px);
                  margin-bottom: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .candidate {
    width: 760px;

    .candidate__container {
      .candidate__container__title {
        margin: 0 auto 50px auto;

        .site-header2 {
          @include font-size(56px);
          line-height: 56px;
        }
      }

      .candidate__container__introduction {
        @include font-size(18px);
        @include line-height(22px);
        font-weight: 500;
        margin: 0 auto 40px auto;
      }

      .candidate__container__candidates-text {
        margin-bottom: 20px;
      }

      .candidate__container__grid {
        display: grid;
        grid-template-columns: 360px 360px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .candidate {
    width: calc(100% - 40px);

    .candidate__container {
      .candidate__container__title {
        width: calc(100% - 40px);
      }

      .candidate__container__candidates-text {
        width: 320px;
        margin: 0 auto 20px auto;
      }

      .candidate__container__grid {
        display: grid;
        grid-template-columns: 320px;
        margin: 0 auto;
        width: 320px;
        .candidate__container__grid__item {
          .candidate__container__grid__item__image {
            width: 320px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .candidate {
    .candidate__container {
      .candidate__container__title {
        .site-header2 {
          @include font-size(34px);
        }
      }

      .candidate__container__candidates-text {
        width: calc(100% - 20px);
      }

      .candidate__container__grid {
        display: grid;
        grid-template-columns: 100%;
        margin: 0 auto;
        width: 100%;

        .candidate__container__grid__item {
          .candidate__container__grid__item__image {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
