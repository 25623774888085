@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.border-title {
  display: block;
  text-align: center;

  .border-title__title {
    display: inline-block !important;
    margin: 0 auto !important;
    width: auto !important;

    @include font-size(42px);
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;

    .border-title__title__border {
      width: 65%;
      margin: 5px auto 0 auto;
      height: 5px;
      background: linear-gradient(
        45deg,
        #b58612 0%,
        #f7ea91 38.96%,
        #b78313 100%
      );
    }
  }
}

@media screen and (max-width: 1350px) {
  .border-title {
    .border-title__title {
      .border-title__title__border {
        height: 3px;
      }
    }
  }
}
