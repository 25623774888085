@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.usage-terms-bg-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  min-height: 320px;
  @include user-select(none);
  object-fit: cover;
  object-position: 50% 0;
}

.usage-terms {
  padding: 5vw 20px 0 20px;
  width: 960px;
  margin: 0 auto;

  .usage-terms__link-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .usage-terms__link-container__link {
      @include font-size(20px);
      margin-bottom: 5px;
    }
  }

  .usage-terms__title {
    margin-bottom: 20px;
  }

  .usage-terms__section {
    margin-bottom: 30px;

    .usage-terms__section__title {
      @include font-size(22px);
      @include line-height(30px);
      margin-bottom: 10px;
      font-weight: 900 !important;
    }

    .usage-terms__section__text {
      @include font-size(22px);
      @include line-height(30px);
      margin-bottom: 5px;

      b {
        font-weight: 900 !important;
      }

      ul {
        display: block;
        list-style-type: disc;

        li {
          margin-left: 20px;
        }
      }

      a {
        word-break: break-all;
      }

      table {
        tr {
          th {
            border: 1px solid white;
            padding: 2px 5px;
            font-weight: 900 !important;

            &:nth-of-type(3) {
              word-break: break-all;
            }
          }
          td {
            border: 1px solid white;
            padding: 2px 5px;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .usage-terms {
    width: calc(100% - 40px);

    .usage-terms__section {
      .usage-terms__section__title {
        @include font-size(16px);
        @include line-height(22px);
      }

      .usage-terms__section__text {
        @include font-size(16px);
        @include line-height(22px);
      }
    }
  }
}
