@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.privacy-policy {
  margin: 5px 0 0 0;

  .privacy-policy__icon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }

  .privacy-policy__link {
    @include font-size(16px);
    @include line-height(25px);
    vertical-align: top;
  }
}
.accept-privacy-policies {
  margin-bottom: 10px;

  input {
    height: auto;
    vertical-align: middle;
    margin: auto 10px auto 0;
  }

  label {
    vertical-align: middle;
    @include user-select(none);
    @include font-size(16px);
  }
}
