@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.best-from-sports {
  .best-from-sports__title {
    margin: 80px 0 40px 0;
  }

  .best-from-sports__grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;

    .best-from-sports__grid__item {
      display: flex;
      flex-direction: column;

      .best-from-sports__grid__item__category-letter {
        color: $color-gold;
        margin-bottom: 20px;
        text-align: center;
      }

      .best-from-sports__grid__item__candidate {
        margin-bottom: 20px;
        text-align: center;

        .best-from-sports__grid__item__candidate__sport {
          @include font-size(18px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
          color: $color-gold;
          text-transform: uppercase;
        }
        .best-from-sports__grid__item__candidate__name {
          @include font-size(18px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .best-from-sports {
    .best-from-sports__title {
      margin: 40px auto;

      .site-header2 {
        @include font-size(32px);
        margin: 0 auto;
      }
    }

    .best-from-sports__grid {
      grid-template-columns: 320px;
      width: 320px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 360px) {
  .best-from-sports {
    .best-from-sports__grid {
      grid-template-columns: calc(100% - 20px);
      width: calc(100% - 20px);
    }
  }
}
