@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.voting-instructions-page-bg-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  max-height: calc(100% - 40px);
  min-height: 320px;
  @include user-select(none);
  object-fit: cover;
  object-position: 50% 0;
}

.voting-instructions-page {
  padding: 5vw 20px 0 20px;
  width: 960px;
  margin: 0 auto;

  .voting-instructions-page__content-container {
    display: flex;
    flex-direction: column;

    .voting-instructions-page__content-container__title {
      color: $color-gold;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
      @include font-size(24px);
      font-family: 'Oswald', sans-serif;
    }

    .voting-instructions-page__content-container__text {
      @include font-size(22px);
      @include line-height(30px);
      margin-bottom: 20px;

      b {
        font-weight: 900 !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .voting-instructions-page {
    width: calc(100% - 40px);

    .voting-instructions-page__content-container {
      .voting-instructions-page__content-container__text {
        @include font-size(16px);
        @include line-height(22px);
      }
    }
  }
}
