@import 'src/styles/_mixins.scss';
@import 'src/styles/_variables.scss';

.newsarchive {
  padding: 6vw 20px 0 20px;
  width: 1160px;
  margin: 0 auto;

  .newsarchive__bg-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    max-height: calc(100% - 40px);
    min-height: 320px;
    @include user-select(none);
    object-fit: cover;
    object-position: 50% 0;
  }
  .newsarchive__title {
    margin-bottom: 70px;
  }

  .newsarchive__newsitem-container {
    .newsarchive__newsitem__date {
      color: $color-gold;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
      @include font-size(24px);
      font-family: 'Oswald', sans-serif;
    }

    .newsarchive__newsitem__text-container {
      max-height: 125px;
      overflow: hidden;
      margin-bottom: 40px;

      .newsarchive__newsitem__text-container__text {
        @include font-size(24px);
        @include line-height(30px);
        b {
          font-weight: 900 !important;
        }
        // Sets gradual fading of the text content.
        // If shows inconsistantly, look how long the text is (including hidden parts like overflow and html code)
        mask-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0) 10%,
          rgba(255, 255, 255, 1)
        );
        -webkit-mask-image: -webkit-linear-gradient(
          bottom,
          rgba(0, 0, 0, 0) 10%,
          rgba(255, 255, 255, 1)
        );
      }
    }
  }
  .newsarchive__newsitem-container:hover,
  .newsarchive__newsitem-container:active {
    cursor: pointer;
  }
}

@media screen and (max-width: 1350px) {
  .newsarchive {
    width: auto !important;
  }
  .newsarchive__newsitem-container__fade {
    width: 100%;
  }
}
