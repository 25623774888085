@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.footer {
  height: 300px;
  width: calc(100% - 40px);
  padding: 0 20px;
  margin: 150px auto -40px auto;
  position: relative;
  background-color: $color-black;
  display: flex;
  flex-direction: column;

  .footer__fade {
    background: linear-gradient(
      0deg,
      $color-black 0%,
      $color-black 50%,
      rgba(51, 51, 51, 0) 100%
    );
    position: absolute;
    top: -120px;
    left: 0;
    width: 100%;
    height: 120px;
  }

  .footer__codemate-link {
    margin: 0 auto;

    .footer__codemate-link__image {
      height: 52px;
      width: 250px;
      opacity: 0.5;
      margin: 30px auto 120px auto;
      display: inline-block;
      background-image: url('../../../public/images/made_with_love_codemate.png');
      background-repeat: no-repeat;
    }
  }

  .footer__link-container {
    margin: auto auto 0 auto;
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    grid-gap: 20px;
    width: 1160px;

    .footer__link-container__item {
      margin: auto;

      .footer__link-container__item__link {
        @include font-size(16px);
        @include line-height(18px);
        vertical-align: top;
      }
    }

    .footer__link-container__item__icons-container {
      display: grid;
      grid-template-columns: 33% 34% 33%;
      margin: auto;

      .footer__link-container__item__icons-container__item {
        padding: 0 10px 0 10px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .footer {
    width: calc(100% - 40px);
    margin: 140px auto 20px auto;
    height: auto;

    .footer__link-container {
      margin: 100px auto -40px auto;
      grid-template-columns: 100%;
      width: calc(100% - 40px);

      .footer__link-container__item {
        margin: auto;
        text-align: center;
      }
    }
  }
}
