@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.committee-page-bg-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  max-height: calc(100% - 40px);
  min-height: 320px;
  @include user-select(none);
  object-fit: cover;
  object-position: 50% 0;
}

.committee-page {
  padding: 5vw 20px 0 20px;
  width: 960px;
  margin: 0 auto;

  .committee-page__text {
    @include font-size(22px);
    @include line-height(30px);
    margin: 40px 0;

    b {
      font-weight: 900 !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .committee-page {
    width: calc(100% - 40px);

    .committee-page__text {
      @include font-size(16px);
      @include line-height(22px);
    }
  }
}
