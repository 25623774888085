@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.confirm-modal {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-height: 150px;
  padding: 40px;
  background-color: $color-grey;

  .confirm-modal__text {
    @include font-size(22px);
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
  }

  .confirm-modal__secondary-text {
    @include font-size(16px);
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    margin: 20px 0;
  }

  .confirm-modal__button-container {
    margin: auto auto 0 auto;
    width: 100%;
    display: flex;

    .confirm-modal__button-container__confirm-button {
      margin: 0 auto;
    }

    .confirm-modal__button-container__cancel-button {
      margin: 0 auto;
      color: $color-white;
      background-color: transparent;
      border: 2px solid $color-white;
    }
  }
}

@media screen and (max-width: 900px) {
  .confirm-modal {
    width: calc(100% - 80px);
    height: 100vh;

    .confirm-modal__text {
      margin-top: 30px;
      text-align: center;
      width: 100%;
    }

    .confirm-modal__secondary-text {
      text-align: center;
      width: 100%;
    }

    .confirm-modal__button-container {
      margin: 30px auto 0 auto;
      width: calc(100% - 80px);
    }
  }
}

@media screen and (max-width: 500px) {
  .confirm-modal {
    .confirm-modal__button-container {
      flex-direction: column;

      .confirm-modal__button-container__confirm-button {
        order: 1;
        margin-bottom: 10px;
      }
      .confirm-modal__button-container__cancel-button {
        order: 2;
      }
    }
  }
}
