@import 'src/styles/_variables.scss';

.icon-input-container {
  position: relative;

  .icon-input-container__icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .icon-input-container__input {
    padding: 4px 0 0 45px;
    width: 100%;
    box-sizing: border-box;
  }

  .icon-input-container__alert-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .icon-input-container__input--invalid {
    border: 2px solid $color-orange;
    padding: 4px 45px 0 45px;

    &::placeholder {
      color: $color-orange;
    }
  }
}
