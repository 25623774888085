@import 'src/styles/_variables.scss';
@import 'src/styles/_mixins.scss';

.candidate-container {
  display: grid;
  grid-template-columns: 600px 560px;
  grid-gap: 10px;
  margin-bottom: 70px;

  .candidate-container__item {
    &:first-of-type {
      grid-column: 1 / 3;
    }

    .candidate-container__item--first {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 400px;
      height: 400px;
      position: relative;
      margin-bottom: 15px;

      .candidate-container__item--first__image {
        @include user-select(none);
        object-fit: cover;
        object-position: 50% 0;
        height: 400px;
        width: 600px;
      }

      .candidate-container__item--first__fade {
        position: absolute;
        top: 0;
        right: 570px;
        height: 400px;
        width: 300px;
        background: linear-gradient(
          270deg,
          $color-grey 0%,
          rgba(51, 51, 51, 0) 100%
        );
      }

      .candidate-container__item--first__summary {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px 10px;
        background-color: $color-grey;
        width: 530px;
        position: relative;

        .candidate-container__item--first__summary__category-title {
          margin: auto 0;
        }

        .candidate-container__item--first__summary__category-image {
          height: 140px;
          margin: auto;
          object-fit: contain;
        }

        .candidate-container__item--first__summary__name {
          @include font-size(42px);
          text-transform: uppercase;
          font-weight: 600;
          font-family: 'Oswald', sans-serif;
          line-height: 1.1;
        }

        .candidate-container__item--first__summary__sport {
          @include font-size(24px);
          text-transform: uppercase;
          font-weight: 600;
          font-family: 'Oswald', sans-serif;
          color: $color-gold;
          margin: 0 0 auto 0;
        }

        .candidate-container__item--first__summary__points-container {
          display: flex;
          justify-content: space-between;

          .candidate-container__item--first__summary__points-container__points {
            display: flex;
            justify-content: space-between;
            width: calc(50% - 20px);

            &:first-of-type {
              margin-right: 30px;
            }

            &:nth-of-type(2) {
              width: 240px;
            }

            p {
              @include font-size(24px);
              text-transform: uppercase;
              font-weight: 600;
              line-height: 36px;
              font-family: 'Oswald', sans-serif;

              &:first-of-type {
                &:after {
                  content: '.............................................................';
                }
                overflow: hidden;
                text-overflow: clip;
                white-space: nowrap;
              }

              &:nth-of-type(2) {
                flex: 1;
                text-align: right;
              }
            }
          }
        }
      }
    }

    .candidate-container__item--other {
      display: flex;
      margin-top: 10px;

      .candidate-container__item--other__order {
        height: 50px;
        width: 60px;
        color: $color-gold;
        margin: 0 0 auto 0;
        line-height: 35px;
      }

      .candidate-container__item--other__info-container {
        display: flex;
        flex-direction: column;
        margin: 0 10px auto 10px;
        width: 210px;

        .candidate-container__item--other__info-container__name {
          @include font-size(18px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
        }

        .candidate-container__item--other__info-container__sport {
          @include font-size(14px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;
          color: $color-gold;
          text-transform: uppercase;
        }
      }

      .candidate-container__item--other__points-container {
        display: flex;
        min-width: 245px;
        margin: 0 0 auto 0;
        flex: 1;

        .candidate-container__item--other__points-container__points {
          @include font-size(18px);
          font-weight: 500;
          font-family: 'Oswald', sans-serif;

          &:first-of-type {
            margin-right: 30px;
            width: calc(50% - 30px);
          }
        }

        .candidate-container__item--other__points-container__points-mobile {
          display: none;
        }
      }
    }
  }

  .candidate-container__horizontal-ruler {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    grid-column-start: 1;
    grid-column-end: span 2;

    .candidate-container__horizontal-ruler__center-text {
      color: #ddb54c;
      font-family: 'Oswald', sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0.75rem;
      text-transform: uppercase;
    }

    .candidate-container__horizontal-ruler__left-decoration {
      background: linear-gradient(270deg, #ddb54c 0%, #141414 50%);
      flex-grow: 2;
      height: 2px;
    }
    .candidate-container__horizontal-ruler__right-decoration {
      background: linear-gradient(90deg, #ddb54c 0%, #141414 50%);
      flex-grow: 2;
      height: 2px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .candidate-container {
    grid-template-columns: 320px;
    width: 320px;
    margin: 0 auto 50px auto;

    .candidate-container__item {
      &:first-of-type {
        grid-column: 1 / 1;
      }

      .candidate-container__item--first {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: auto;

        .candidate-container__item--first__image {
          width: 320px;
          height: 320px;
        }

        .candidate-container__item--first__fade {
          top: 70px;
          left: 0;
          height: 250px;
          width: 320px;
          background: linear-gradient(
            0,
            $color-grey 0%,
            rgba(51, 51, 51, 0) 100%
          );
        }

        .candidate-container__item--first__summary {
          width: 280px;
          padding: 20px;

          .candidate-container__item--first__summary__category-image {
            height: 100px;
            margin: 0 auto;
          }

          .candidate-container__item--first__summary__title {
            @include font-size(24px);
            margin: 0 auto 20px auto;
          }

          .candidate-container__item--first__summary__name {
            @include font-size(24px);
          }

          .candidate-container__item--first__summary__sport {
            @include font-size(16px);
          }

          .candidate-container__item--first__summary__points-container {
            .candidate-container__item--first__summary__points-container__points {
              &:nth-of-type(2) {
                width: auto;
                flex: 1;
              }

              p {
                @include font-size(14px);

                &:first-of-type {
                  margin-right: 10px;
                  &:after {
                    content: ':';
                  }
                }
              }
            }
          }
        }
      }

      .candidate-container__item--other {
        .candidate-container__item--other__order {
          @include font-size(24px);
          height: 32px;
          width: 40px;
        }

        .candidate-container__item--other__info-container {
          width: 150px;

          .candidate-container__item--other__info-container__name {
            @include font-size(14px);
          }

          .candidate-container__item--other__info-container__sport {
            @include font-size(10px);
          }
        }

        .candidate-container__item--other__points-container {
          margin: 10px 0 auto auto;
          width: 130px;
          min-width: 130px;

          .candidate-container__item--other__points-container__points {
            display: none;
          }

          .candidate-container__item--other__points-container__points-mobile {
            display: inline-block;
            font-weight: 500;
            font-family: 'Oswald', sans-serif;
            @include font-size(14px);
            margin-left: 10px;
          }
        }
      }
    }

    .candidate-container__horizontal-ruler {
      grid-column-end: span 1;
    }
  }
}

@media screen and (max-width: 360px) {
  .candidate-container {
    grid-template-columns: 280px;
    width: 280px;

    .candidate-container__item {
      .candidate-container__item--first {
        .candidate-container__item--first__image {
          width: 280px;
          height: 280px;
        }

        .candidate-container__item--first__fade {
          width: 280px;
          top: 30px;
        }

        .candidate-container__item--first__summary {
          width: 240px;
        }
      }
    }
  }
}
